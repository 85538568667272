define("apollo/pods/components/price-list/routes-table/component", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUtilsMixin.default, {
    tagName: 'div',
    classNames: Ember.A(['price-list-table']),
    intl: Ember.inject.service(),
    unloadRouteAddresses: Ember.computed('routeAddresses.{[],length}', function () {
      return this.get('routeAddresses').filter(ra => ra.get('stepType') === 'unload').uniqBy('address.id');
    }),
    loadRouteAddresses: Ember.computed('routeAddresses.{[],length}', function () {
      return this.get('routeAddresses').filter(ra => ra.get('stepType') === 'load').uniqBy('address.id');
    }),
    maxRowsInTable: Ember.computed('maxRowsInTable', function () {
      return localStorage.getItem('MAX_ROWS_IN_PRICE_LIST_TABLE') || 10;
    }),
    _filterCountries: function (addresses) {
      const countries = [];

      if (!addresses) {
        return [];
      }

      addresses.forEach(ra => {
        const obj = {
          value: ra.get('address.country.ibanSymbol'),
          label: ra.get('address.country.ibanSymbol')
        };

        if (!countries.some(c => c.value === obj.value)) {
          countries.push(obj);
        }
      });
      return countries;
    },
    loadCountries: Ember.computed('loadRouteAddresses.{[],length}', function () {
      return this._filterCountries(this.get('loadRouteAddresses'));
    }),
    unloadCountries: Ember.computed('unloadRouteAddresses.{[],length}', function () {
      return this._filterCountries(this.get('unloadRouteAddresses'));
    }),
    columns: Ember.computed(function () {
      const table = [{
        propertyName: 'routeId',
        title: this.get('intl').t('common.id'),
        className: 'route-id',
        datafieldName: 'IDENTIFIER'
      }, {
        propertyName: 'routeLoadAddressRecipient',
        title: this.get('intl').t('route.address.load'),
        className: 'route-load-address-recipient',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('loadRouteAddresses').map(ra => {
          return {
            value: ra.get('address.recipient'),
            label: ra.get('address.recipient')
          };
        }),
        datafieldName: 'LOAD_ADDRESS'
      }, {
        propertyName: 'routeLoadAddressCountry',
        title: this.get('intl').t('route.address.country'),
        className: 'route-load-address-country',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('loadCountries'),
        datafieldName: 'LOAD_COUNTRY'
      }, {
        propertyName: 'routeLoadAddressPostal',
        title: this.get('intl').t('route.address.postal'),
        className: 'route-load-address-postal',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('loadRouteAddresses').map(ra => {
          return {
            value: ra.get('address.postal'),
            label: ra.get('address.postal')
          };
        }),
        datafieldName: 'LOAD_POSTAL'
      }, {
        propertyName: 'routeLoadAddressCity',
        title: this.get('intl').t('route.address.city'),
        className: 'route-load-address-city',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('loadRouteAddresses').map(ra => {
          return {
            value: ra.get('address.city'),
            label: ra.get('address.city')
          };
        }),
        datafieldName: 'LOAD_CITY'
      }, {
        propertyName: 'routeUnloadAddressRecipient',
        title: this.get('intl').t('route.address.unload'),
        className: 'route-unload-address-recipient',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('unloadRouteAddresses').map(ra => {
          return {
            value: ra.get('address.recipient'),
            label: ra.get('address.recipient')
          };
        }),
        datafieldName: 'UNLOAD_ADDRESS'
      }, {
        propertyName: 'routeUnloadAddressCountry',
        title: this.get('intl').t('route.address.country'),
        className: 'route-unload-address-country',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('unloadCountries'),
        datafieldName: 'UNLOAD_COUNTRY'
      }, {
        propertyName: 'routeUnloadAddressPostal',
        title: this.get('intl').t('route.address.postal'),
        className: 'route-unload-address-postal',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('unloadRouteAddresses').map(ra => {
          return {
            value: ra.get('address.postal'),
            label: ra.get('address.postal')
          };
        }),
        datafieldName: 'UNLOAD_POSTAL'
      }, {
        propertyName: 'routeUnloadAddressCity',
        title: this.get('intl').t('route.address.city'),
        dataColumnName: 'route-unload-address-city',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('unloadRouteAddresses').map(ra => {
          return {
            value: ra.get('address.city'),
            label: ra.get('address.city')
          };
        }),
        datafieldName: 'UNLOAD_CITY'
      }]; // Custom Fieldy

      const definitions = this.get('transportType.customFieldDefinitionsIncludedInPriceList');
      definitions.forEach(cfd => {
        const name = cfd.get('nameInAppropriateLanguage');
        const cf = {
          propertyName: 'routeCustomField',
          title: name,
          className: cfd.get('englishName'),
          dataClassName: cfd.get('englishName'),
          filterWithSelect: true,
          disableFiltering: true,
          // TODO A-11168
          predefinedFilterOptions: []
        };
        const options = [];
        cfd.options.forEach(option => {
          options.push(option.get('name'));
        });
        cf.predefinedFilterOptions = options;
        table.push(cf);
      }); // Najlepsza oferta

      table.push({
        propertyName: 'routeBestOffer',
        title: this.get('intl').t('route.bestOffer')
      });
      return table;
    }),
    actions: {
      showModal(modal, route) {
        this.set('action', 'showModal');
        this.sendAction('action', modal, route);
      },

      setMaxRows(mt) {
        const count = mt.publicAPI.recordsCount;
        localStorage.setItem('MAX_ROWS_IN_PRICE_LIST_TABLE', count);
        const msg = this.get('intl').t('common.saveSuccessMessage');
        this.set('saveSuccessMessage', msg);
      }

    }
  });

  _exports.default = _default;
});