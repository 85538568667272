define("apollo/pods/components/custom-auto-complete/component", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let focusOutEvent;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend({
    sessionAccount: service('session-account'),
    highlightIndex: -1,
    escapedChars: [40, 38, 13],
    visibility: Ember.String.htmlSafe('display: none;'),
    inputClass: '',
    inputClazz: Ember.computed(function () {
      return 'typeahead form-control ' + this.get('inputClass');
    }),
    classNames: ['custom-auto-complete'],
    layoutName: 'components/custom-auto-complete',
    allowEnteringNewValue: false,
    defaultMax: 10,
    max: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('AUTO_COMPLETE_MAX') || this.get('defaultMax');
    }),
    queryInputChanged: function () {
      Ember.run.debounce(this, this.getOptionsFromServer, 500);
    }.observes('inputVal'),

    getOptionsFromServer() {
      const self = this;
      const inputVal = this.get('inputVal') || '';
      const data = {
        query: inputVal,
        field: this.field,
        type: this.type,
        autocomplete: true,
        companyIds: this.companyIds,
        companyId: this.companyId,
        max: this.get('max'),
        showPrincipalCompanies: this.showPrincipalCompanies
      };
      const dataOptions = this.getDataOptions ? this.getDataOptions() : null;

      if (dataOptions) {
        Object.assign(data, dataOptions);
      } // TODO :: apollo-api-service


      Ember.$.ajax({
        type: 'GET',
        url: _environment.default.serverURL + this.urlAddress,
        data: data,
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('sessionAccount').get('token'));
        }
      }).then(function (resp) {
        self.set('options', resp);
      });
    },

    dropDownStyle: function () {
      return 'visibility: ' + this.get('visibility');
    }.property('visibility'),
    click: function () {
      if (this.$().find('input').is(':disabled') || this.get('asPlainTemplate')) {
        console.debug(`Element is disabled - not showing options.`);
        return;
      }

      if (typeof this.get('selectedValue') === 'undefined') {
        this.set('inputVal', "");
      }

      Ember.run.debounce(this, this.getOptionsFromServer, 500);
      this.set('visibility', Ember.String.htmlSafe('display: block;'));
    },
    keyUp: function (event) {
      if (event.keyCode === 27) {
        this.set('visibility', Ember.String.htmlSafe('display: none;'));
      } else if (this.escapedChars.indexOf(event.keyCode) === -1) {
        this.set('visibility', Ember.String.htmlSafe('display: block;'));
        this.set('inputVal', Ember.$(event.target).val());
      }
    },
    focusIn: function () {
      this.set('selectedFromList', false);

      if (this.get('visibility') === 'display:none;') {
        this.set('visibility', Ember.String.htmlSafe('display: block;'));
      }
    },
    focusOut: function () {
      clearTimeout(focusOutEvent);
      const self = this;

      const func = function () {
        if (self.isDestroyed) {
          return;
        }

        self.set('visibility', Ember.String.htmlSafe('display: none;'));

        if (self.get('selectedFromList')) {
          return;
        }

        const value = this.get('selectedValue');
        const optionsToMatch = this.get('optionsToMatch') || [];
        const prevValue = self.get('attrs.selectedValue');

        if (self.get('allowEnteringNewValue')) {
          console.debug(`This field allows entering new values..`);
          self.sendAction('newValueEntered', value);
        } else if (optionsToMatch.indexOf(value) === -1) {
          if (value && value !== prevValue) {
            console.log(`Adding new option is disallowed. Restoring previous value - ${prevValue}..`);
            self.set('inputVal', prevValue);
            self.set('selectedValue', prevValue);
          } else if (!value) {
            console.debug(`User has removed value - we're removing selection..`);
            self.sendAction('clearSelection');
          }
        }
      };

      focusOutEvent = Ember.run.later(this, func, 200);
    },
    keyDown: function (event) {
      const ENTER_KEY_CODE = 13;

      if (event.keyCode === ENTER_KEY_CODE) {
        event.preventDefault();
      }

      const $container = $(event.target).parent('.typeahead-wrap').find('.tt-suggestions');

      if ($container.is(':visible')) {
        if (event.keyCode === 40) {
          this.highlight('down', event);
        } else if (event.keyCode === 38) {
          this.highlight('up', event);
        } else if (event.keyCode === ENTER_KEY_CODE || event.keyCode === 9) {
          if (!Ember.isBlank(this.get('selectableSuggestion'))) {
            this.send('selectItem', this.get('selectableSuggestion'));
            this.set('visibility', Ember.String.htmlSafe('display: none;'));
          } else {
            const value = this.get('selectedValue');
            const optionsToMatch = this.get('optionsToMatch');

            if (optionsToMatch && optionsToMatch.indexOf(value) >= 0) {
              console.log(`selectedFromList..`);
              this.set('selectedFromList', true);
              this.set('visibility', Ember.String.htmlSafe('display: none;'));
            }
          }
        }
      } else {
        this.set('visibility', Ember.String.htmlSafe('display: block;'));
      }
    },
    highlight: function (direction, event) {
      let newHighlightIndex = -1;

      if (direction === 'down') {
        newHighlightIndex = this.highlightIndex + 1;
      } else if (this.highlightIndex > 0) {
        newHighlightIndex = this.highlightIndex - 1;
      }

      if (newHighlightIndex < this.get('suggestions').length) {
        if (this.highlightIndex > -1) {
          const currentResult = this.get('suggestions').objectAt(this.highlightIndex);
          Ember.set(currentResult, 'highlight', false);
        }

        this.set('highlightIndex', newHighlightIndex);

        if (this.highlightIndex > -1) {
          const nextResult = this.get('suggestions').objectAt(this.highlightIndex);
          Ember.set(nextResult, 'highlight', true);
          this.set('selectableSuggestion', nextResult);
        }
      }

      if (newHighlightIndex > -1 && newHighlightIndex < this.get('suggestions.length')) {
        const $container = $(event.target).parent('.typeahead-wrap').find('.tt-suggestions');
        const $elementToScrollTo = $($container.find('.autocomplete-result')[newHighlightIndex]);
        $container.scrollTop($elementToScrollTo.offset().top - $container.offset().top + $container.scrollTop() - 5);
      }
    }
  });

  _exports.default = _default;
});