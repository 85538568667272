define("apollo/pods/components/create-transport-button/component", ["exports", "apollo/mixins/step-mixin"], function (_exports, _stepMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stepMixin.default, {
    tagName: 'a',
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    warehouseZoneTransportTypes: Ember.computed('', function () {
      return this.get('store').peekAll('warehouse-zone-transport-type');
    }).readOnly(),
    handleDefaultCompanies: function (transport) {
      const config = {
        load: 'defaultLoadCompanyId',
        unload: 'defaultUnloadCompanyId'
      };

      for (const stepType in config) {
        const property = config[stepType];
        const defaultCompanyId = transport.get(`transportType.${property}`);

        if (defaultCompanyId) {
          const steps = transport.get('steps').filter(s => s.get('stepTypeName') === stepType);
          this.get('store').findRecord('company', defaultCompanyId).then(c => {
            steps.forEach(s => {
              s.set('company', c);

              if (s.get('newAddressAsDefault')) {
                s.set('address', this.get('store').createRecord('address', {
                  company: c
                }));
              }
            });
          });
        }
      }
    },
    actions: {
      openTransportModal(transportType) {
        const store = this.get('store');
        const transport = store.createRecord('transport', {
          transportType
        });
        console.log('Otwieramy okienko z formularzem nowego transportu...');

        if (transportType.get('requiresTimeWindow') && !transportType.get('adviceSeparatedFromWindow') || transportType.get('adviceSavedWithTransport')) {
          const advice = store.createRecord('advice', {
            transport
          });
          transport.set('advice', advice);
        }

        const warehouses = transportType.get('warehousesForCurrentUser');
        const isLoadStepInWarehouseByDefault = transportType.get('isLoadStepInWarehouseByDefault');
        const isUnloadStepInWarehouseByDefault = transportType.get('isUnloadStepInWarehouseByDefault');
        const ramp = this.get('rampId') ? store.peekRecord('ramp', this.get('rampId')) : null;
        const timeWindow = ramp ? store.createRecord('timeWindow', {
          ramp,
          start: Date.parse(this.get('timeWindowStart')),
          stop: Date.parse(this.get('timeWindowStop'))
        }) : null;
        this.get('sessionAccount.currentUser.company').then(comp => {
          if (comp.get('partnershipType.name') === 'CARRIER') {
            transport.set('carrierCompany', comp);
          }
        });
        const TYPE_GROUPS = transportType.get('TYPE_GROUPS');

        if (transportType.get('typeGroup') === TYPE_GROUPS.COLLECTION) {
          // odbiór - pierwszy etap w magazynie, dowolna ilość rozładunków
          const loadStep = store.createRecord('step', {
            ownedByPrincipal: isLoadStepInWarehouseByDefault,
            shouldHaveContractor: !transportType.get('hasVisibleSteps'),
            stepTypeName: 'load',
            orderInTransport: 0,
            timeWindow
          });

          if (warehouses.get('length') === 1) {
            loadStep.set('warehouse', warehouses.get('firstObject'));
          }

          transport.get('steps').pushObject(loadStep);

          if (transportType.get('hasVisibleSteps')) {
            const unloadStep = store.createRecord('step', {
              ownedByPrincipal: isUnloadStepInWarehouseByDefault,
              shouldHaveContractor: true,
              stepTypeName: 'unload',
              orderInTransport: 1
            });
            transport.get('steps').pushObject(unloadStep);
          }
        } else if (transportType.get('typeGroup') === TYPE_GROUPS.DELIVERY) {
          // dostawa - ostatni etap w magazynie, dowolna ilość załadunków lub kurier - przyjazd do klienta, tylko jeden "załadunek"
          const loadStep = store.createRecord('step', {
            ownedByPrincipal: isLoadStepInWarehouseByDefault,
            shouldHaveContractor: true,
            stepTypeName: 'load',
            orderInTransport: 0
          });
          transport.get('steps').pushObject(loadStep);
          const unloadStep = store.createRecord('step', {
            ownedByPrincipal: isUnloadStepInWarehouseByDefault,
            shouldHaveContractor: false,
            stepTypeName: 'unload',
            orderInTransport: 1
          });

          if (warehouses.get('length') === 1) {
            unloadStep.set('warehouse', warehouses.get('firstObject'));
          }

          transport.get('steps').pushObject(unloadStep);
        } else if (transportType.get('typeGroup') === TYPE_GROUPS.SIMPLIFIED_DELIVERY || transportType.get('typeGroup') === TYPE_GROUPS.SERVICE) {
          // Uproszczona dostawa - jeden etap - w magazynie
          const unloadStep = store.createRecord('step', {
            ownedByPrincipal: isUnloadStepInWarehouseByDefault,
            shouldHaveContractor: transportType.get('allowsContractor'),
            stepTypeName: 'unload',
            orderInTransport: 0
          });

          if (warehouses.get('length') === 1) {
            unloadStep.set('warehouse', warehouses.get('firstObject'));
          }

          transport.get('steps').pushObject(unloadStep);
        } else if (transportType.get('typeGroup') === TYPE_GROUPS.TRANSPORT) {
          // zlecenie transportowe - dowolna ilość etapów, w dowolnej konfiguracji
          const loadStep = store.createRecord('step', {
            ownedByPrincipal: isLoadStepInWarehouseByDefault,
            shouldHaveContractor: true,
            stepTypeName: 'load',
            orderInTransport: 0
          });
          transport.get('steps').pushObject(loadStep); // zakładamy, że najczęściej 'transport' będzie kończył się rozładunkiem w magazynie klienta;
          // użytkownik będzie mógł to zmienić podczas tworzenia transportu

          const unloadStep = store.createRecord('step', {
            ownedByPrincipal: isUnloadStepInWarehouseByDefault,
            shouldHaveContractor: false,
            stepTypeName: 'unload',
            orderInTransport: 1
          });
          transport.get('steps').pushObject(unloadStep);
        } else if (transportType.get('typeGroup') === TYPE_GROUPS.TRANSFER_BETWEEN_WAREHOUSES) {
          // Przerzuty - dwa etapy - oba w magazynie
          const loadStep = store.createRecord('step', {
            ownedByPrincipal: isLoadStepInWarehouseByDefault,
            shouldHaveContractor: false,
            stepTypeName: 'load',
            orderInTransport: 0
          });
          transport.get('steps').pushObject(loadStep);
          const unloadStep = store.createRecord('step', {
            ownedByPrincipal: isUnloadStepInWarehouseByDefault,
            shouldHaveContractor: false,
            stepTypeName: 'unload',
            orderInTransport: 1
          });
          transport.get('steps').pushObject(unloadStep);
        } // Ustawiamy domyślny czas dostawy etapom, które nie znajdują się w magazynie -
        // tylko w przypadku transportów, w których można podawać etapy.


        if (transportType.get('hasVisibleSteps')) {
          transport.get('steps').filterBy('ownedByPrincipal', false).forEach(step => {
            // TODO: parametryzacja godziny - do zrobienia również w step-header/controller.js#addStep
            const numberOfDaysForFirstStep = transportType.get('numberOfDaysAddedToFirstStep') ? transportType.get('numberOfDaysAddedToFirstStep') : 0;
            const defaultArrivalDate = moment().startOf('day').add(numberOfDaysForFirstStep, 'day').add(18, 'hours').toDate();
            step.setProperties({
              arrivalDate: defaultArrivalDate
            });
          });
        } // Ustawiamy domyślny zakres czasu na awizację w przypadku transportów, które go wymagają.


        if (transportType.get('requiresAdviceDateRestrictions')) {
          transport.get('steps').filterBy('ownedByPrincipal').forEach(step => {
            step.setAdviceRestrictionDates();
          });
        }

        this.get('sessionAccount.currentUser.company').then(comp => {
          const firstStepForContractor = transport.get('steps').filterBy('ownedByPrincipal', false).get('firstObject') || transport.get('steps.firstObject');

          if (firstStepForContractor) {
            const contractor = comp.get('partnershipType.name') === 'SUPPLIER' || comp.get('partnershipType.name') === 'OPERATOR' ? comp : store.createRecord('company', {});
            firstStepForContractor.set('company', contractor);
          }
        });
        transport.get('transportType').get('customFieldDefinitions').filterBy('enabled').forEach(function (definition) {
          const customField = store.createRecord('customFieldValue', {
            definition
          });
          transport.get('customFields').pushObject(customField);
        });
        transport.set('periodicTransport', this.get('store').createRecord('periodicTransport', {
          frequency: 'DAILY',
          startDate: moment().startOf('day').toDate()
        }));
        const loggedUserCompany = this.get('sessionAccount.currentUser.company');
        const isServiceCompany = loggedUserCompany.get('partnershipType.name') === 'SERVICEMAN';

        if (isServiceCompany) {
          transport.set('serviceCompany', loggedUserCompany);
        }

        if (transportType.get('defaultServicemanCompanyId') && !isServiceCompany) {
          store.findRecord('company', transportType.get('defaultServicemanCompanyId')).then(serviceCompany => transport.set('serviceCompany', serviceCompany));
        } // tworzymy obiekty dla opakowań


        this.handleStepPackageTypesForTransport(transport); // ustawiamy domyślne firmy na załadunku i rozładunku - jeśli transport ma je ustawione

        this.handleDefaultCompanies(transport);

        transport._createAmountOfCover();

        this.set('action', 'showModal');
        this.sendAction('action', 'transports.modals.create-transport', transport);
      }

    }
  });

  _exports.default = _default;
});