define("apollo/helpers/has-unread-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Helper.extend({
    sessionAccount: service('session-account'),

    compute(params) {
      this.set('value', params[0]);
      return this.get('content');
    },

    valueDidChange: Ember.observer('value', function () {
      let value = this.get('value');

      if (Ember.isEmpty(value)) {
        Ember.defineProperty(this, 'content', null);
        return;
      }

      let self = this;
      Ember.defineProperty(this, 'content', Ember.computed('value', function () {
        let value = self.get('value');
        let sessionAccount = self.get('sessionAccount');
        const notesReadByUser = value.filter(n => {
          return n.hasBeenReadBy(sessionAccount);
        });
        return value.get('length') !== notesReadByUser.length;
      }));
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});