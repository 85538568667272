define("apollo/pods/components/driver-auto-complete/component", ["exports", "apollo/pods/components/custom-auto-complete/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    valueProperty: "id",
    urlAddress: "/driver/find",
    field: "driverName",
    options: [],
    suggestions: function () {
      var inputVal = this.get("inputVal") || "";
      return this.get("options").filter(function (item) {
        return item.driverName.toLowerCase().indexOf(inputVal.toLowerCase()) > -1;
      });
    }.property("inputVal", "options.@each"),
    optionsToMatch: function () {
      var caseInsensitiveOptions = [];
      this.get("options").forEach(function (item) {
        var value = item.driverName;
        caseInsensitiveOptions.push(value);
        caseInsensitiveOptions.push(value.toLowerCase());
      });
      return caseInsensitiveOptions;
    }.property("options.@each"),
    actions: {
      selectItem: function (item) {
        this.set("selectedFromList", true);
        this.set("selectedValue", item.driverName);
        this.sendAction('selectItem', item);
      }
    }
  });

  _exports.default = _default;
});