define("apollo/pods/routes/modals/route-form/template/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    routeLabel: Ember.computed('', function () {
      return this.get('route.id') ? `${this.get('intl').t('route.label')}:${this.get('route.id')}` : this.get('intl').t('route.newRoute');
    }),
    actions: {
      saveRoute() {
        this.saveRoute();
      },

      editRoute() {
        this.editRoute();
      },

      close() {
        this.close();
      },

      cancel() {
        this.cancel();
      }

    }
  });

  _exports.default = _default;
});