define("apollo/pods/super-admin/index/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    session: service('session'),
    sessionAccount: service('session-account'),
    store: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    transportsController: Ember.inject.controller('transports/index'),
    actions: {
      impersonate() {
        const self = this;
        const currentToken = self.get('session.data.authenticated.token');
        const usernameToImpersonate = self.get('username'); // TODO :: apollo-api-service

        Ember.$.ajax({
          url: _environment.default.serverURL + `/impersonate/${usernameToImpersonate}`,
          type: 'POST',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + currentToken);
          }
        }).then(data => {
          const token = data.token; // Nadpisujemy dane sesji ember-simple-auth.

          self.get('session').setProperties({
            'data.authenticated.access_token': token,
            'data.authenticated.token': token,
            'data.authenticated.username': usernameToImpersonate
          }); // Nadpisujemy wpis w LocalStorage.

          localStorage['ember_simple_auth-session'] = JSON.stringify({
            'authenticated': {
              'authenticator': 'authenticator:custom',
              'token': token,
              'username': usernameToImpersonate,
              'prevToken': currentToken,
              'prevUsername': 'super-admin'
            }
          }); // Czyścimy ciasteczko zawierające id firmy pryncypała
          // (będzie ponownie uzupełnione po wczytaniu użytkownika).

          localStorage.removeItem('principalId'); // Ładujemy użytkownika na podstawie otrzymanego tokenu.

          self.get('sessionAccount').loadCurrentUser().then(impersonatedUser => {
            console.debug(`Zalogowaliśmy się na użytkownika ${impersonatedUser.get('username')}.`); // Po poprawnym wczytaniu użytkownika, uzupełniamy dane pryncypała..

            const principalCompanyId = localStorage.getItem('principalId');
            const principalCompany = self.get('store').peekRecord('company', principalCompanyId);
            self.get('applicationController').set('principal', principalCompany); // ..i przenosimy użytkownika do widoku tabeli transportów.

            self.get('transportsController').set('content', null);
            self.send('goToTransportTab');
          });
        }, failureData => {
          self.set('errorMessage', failureData.responseText);
        });
      },

      goToTransportTab() {
        this.send('goToTab', 'transports');
      }

    }
  });

  _exports.default = _default;
});