define("apollo/pods/components/route/form/route-address-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    companyToFindAddress: Ember.computed('ra.route.transportType', function () {
      return this.get('ra.stepType') === 'load' ? this.get('ra.route.transportType.defaultLoadCompanyId') : this.get('ra.route.transportType.defaultUnloadCompanyId');
    }),
    // TODO: Możemy to przenieść do address/model.js (`addressSummary` używamy w kilku miejscach)
    addressSummary: Ember.computed('ra.address.{recipient,city,postal,street}', function () {
      if (this.get('ra.address.recipient')) {
        return this.get('ra.address.recipient');
      }

      if (this.get('ra.address.street')) {
        return `${this.get('ra.address.street')}, ${this.get('ra.address.city')}, ${this.get('ra.address.postal')}`;
      }

      return '';
    }).readOnly(),
    actions: {
      recalculateRouteAddressesIdx(routeAddresses, newRouteAddressIdx) {
        routeAddresses.filter(routeAddress => routeAddress.get('idx') >= newRouteAddressIdx).forEach(filteredRouteAddress => {
          filteredRouteAddress.set('idx', filteredRouteAddress.get('idx') + 1);
        });
      },

      recalculateStepTypeIdx(stepTypeAddresses, newStepTypeIdx) {
        stepTypeAddresses.filter(routeAddress => routeAddress.get('stepTypeIdx') >= newStepTypeIdx).forEach(filteredRouteAddress => {
          filteredRouteAddress.set('stepTypeIdx', filteredRouteAddress.get('stepTypeIdx') + 1);
        });
      },

      addRouteAddress: function (routeAddress, stepType) {
        const route = this.get('ra.route');
        const routeAddresses = route.get('routeAddresses');
        const stepTypeRouteAddresses = routeAddresses.filter(ra => {
          return ra.stepType === stepType;
        });
        const previousOrSameStep = routeAddresses.filter(ra => {
          return ra.get('idx') <= routeAddress.get('idx') && ra.get('stepType') === stepType;
        }).reverse().get('firstObject');
        const newStepTypeIdx = previousOrSameStep ? previousOrSameStep.get('stepTypeIdx') + 1 : 0;
        this.send('recalculateStepTypeIdx', stepTypeRouteAddresses, newStepTypeIdx);
        const newRouteAddressIdx = routeAddress.get('idx') + 1;
        this.send('recalculateRouteAddressesIdx', routeAddresses, newRouteAddressIdx);
        const newRouteAddress = this.get('store').createRecord('routeAddress', {
          stepType,
          idx: newRouteAddressIdx,
          stepTypeIdx: newStepTypeIdx
        });
        routeAddresses.addObject(newRouteAddress);
        route.set('routeAddresses', route.get('routeAddresses').sortBy('idx'));
      },
      setAddressData: function (routeAddress, address) {
        if (address.id) {
          this.get('store').findRecord('address', address.id).then(addressModel => {
            routeAddress.set('address', addressModel);
            routeAddress.set('company', addressModel.get('company'));
          });
        } else {
          routeAddress.get('address').setProperties({
            street: address.street,
            postal: address.postal,
            city: address.city
          });
          this.get('store').findRecord('country', address.country).then(countryModel => {
            routeAddress.set('country', countryModel);
          });
          this.get('store').findRecord('company', address.company).then(companyModel => {
            routeAddress.set('company', companyModel);
          });
        }
      },

      removeAddress() {
        this.get('ra').set('address', null);
      },

      beforeDrag: function (address, event) {
        console.log(`Próbujemy przesunąć adres ${address.get('idx')}...`);

        if (!address.get('canBeMoved')) {
          event.preventDefault();
        }
      },

      /**
       *  potrzebne do nadpisania domyślnego stylowania draggable-object
       */
      dragStartHook: function () {}
    }
  });

  _exports.default = _default;
});