define("apollo/pods/components/modals/schedule-action-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    tagName: '',
    transportTypesUserCanCreate: Ember.computed('principal', function () {
      const availableTransportTypes = this.get('principal.transportTypesThatCanBeCreated');
      return availableTransportTypes.filter(tt => {
        return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE', tt.get('id')).length > 0;
      });
    }),
    principal: Ember.computed(function () {
      return this.get('sessionAccount.principalCompany');
    }),
    userHasRoleToCreateDedicatedWindow: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_MANAGE_DEDICATED_WINDOWS');
    }),
    userHasRoleToCreateBlockadeWindow: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS');
    }),
    actions: {
      onCloseHandler: function () {
        this.onClose();
      },
      createBlockedWindow: function () {
        const model = this.get('scheduleActionSelectModel');
        this.createBlockedWindow(model.get('start'), model.get('stop'), model.get('rampId'), model.get('selectedCompany'));
        this.onClose();
      },

      showModal(templateName, checkpoint) {
        // Potrzebne do obsługi otwierania modalu z formularzem transportu
        this.set('action', 'showModal');
        this.sendAction('action', templateName, checkpoint);
      }

    }
  });

  _exports.default = _default;
});