define("apollo/pods/components/transport-row-invoice-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GyuAtAL2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"transaction\",\"invoiceNumber\"]]],null,{\"statements\":[[0,\"   \"],[7,\"div\"],[9],[1,[23,[\"transaction\",\"invoiceNumber\"]],false],[10],[0,\"\\n   \"],[7,\"div\"],[9],[1,[23,[\"transaction\",\"invoicePrice\"]],false],[0,\" \"],[1,[23,[\"transaction\",\"invoiceCurrency\",\"symbol\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"transaction\"]],[23,[\"canAddInvoiceData\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-sm margin-bottom\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"columns.INVOICE\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"toggleInvoiceFormModal\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"shouldShowInvoiceModal\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"modals/transaction-invoice-form\",null,[[\"transaction\",\"onClose\"],[[23,[\"transaction\"]],[27,\"action\",[[22,0,[]],\"toggleInvoiceFormModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport-row-invoice-data/template.hbs"
    }
  });

  _exports.default = _default;
});